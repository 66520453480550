import rust from '../images/rustfivestar.png'
import rainbow from '../images/rainbowfivestar.png'
import tarkov from '../images/tarkovfivestar.png'
import cs2 from '../images/counterstrikefivestar.png'

export const PopularGames = [
    {
        "game": "Rust",
        "image": rust
    },
    {
        "game": "Escape from Tarkov",
        "image": tarkov
    },
    {
        "game": "Counter Strike 2",
        "image": cs2
    },
    {
        "game": "Rainbow Six Siege",
        "image": rainbow
    }
]