import './App.css';
import React from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import {Navbar} from './components/navbar.js'
import {Footer} from './components/footer.js'
import {Home} from './pages/home.js'
import {Products} from './pages/products.js'
import {Product} from './pages/product.js'
import {Faq} from './pages/faq.js'
import {Tos} from './pages/tos.js'
import {Unknown} from './pages/unknown.js'
import {Status} from './pages/status.js'
import {Logo} from './images/logo.png';

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products" element={<Products />} />
        <Route path="/products/:gameUrl/:cheatUrl" element={<Product />} />
        <Route path="/status" element={<Status />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/tos" element={<Tos />} />
        <Route path="/*" element={<Unknown />} />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
