import React, {useState, useEffect, useRef} from "react";
import { BrowserRouter as Router, Routes, Link, Route} from 'react-router-dom';
import {Footer} from '../components/footer.js'
import {data} from '../components/cheats-data.js';
import {Games} from '../components/games.js';

export const Status = () => {
 

 return (
   <>
   <div className="status">
      <div className="status-title"></div>
   
         
      <div className="status-container">
      {Games.map((game) => (
         <div key={game.game} className="status-holder">
            <div className="status-game-title">{game.game}</div>
            <div className="statuses">
            {data
            .filter((cheat) => cheat.game === game.game)
            .map((filteredCheat) => (
               <div key={filteredCheat.name} className="status-card">
                  <div className="status-cheat">{filteredCheat.name}</div>
                  <div className={"status-" + filteredCheat.status}>{filteredCheat.status}</div>
               </div>
            ))}
         </div>
         </div>
      ))}
      </div>
   </div>
  
    </>

 )

}